import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss";
import "assets/demo/demo.css";
import "assets/demo/react-demo.css";
import "assets/demo/nucleo-icons-page-styles.css";
// pages
import AboutUs from "pincelit/views/AboutUs.js";
import News from "pincelit/views/News.js";
import Article from "pincelit/views/Article.js";
import LandingPage from "pincelit/views/LandingPage.js";
// others

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/revista" render={props => <AboutUs {...props} />} />
      <Route path="/noticias" render={props => <News {...props} />} />
      <Route path="/artículo" render={props => <Article {...props} />} />
      <Route
        path="/inicio"
        render={props => <LandingPage {...props} />}
      />
      <Redirect to="/inicio" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
