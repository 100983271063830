import React, { useState } from "react";
import Credentials from '../../credentials';
import { GoogleSpreadsheet } from 'google-spreadsheet';
import { Dots } from 'react-preloaders';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import DropdownFixedNavbar from "pincelit/components/Navbar.js";
import AboutUsHeader from "pincelit/components/AboutUsHeader.js";
import Footer from "pincelit/components/Footer.js";

function AboutUs() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  const [magazine, setMagazine] = useState([]);
  const [loading, setLoading] = useState(true);
  const getDriveImages = function (image = '') {
    if (image.indexOf('drive.google') !== -1) {
      let driveId = image.split('id=')[1];
      return `https://drive.google.com/thumbnail?id=${driveId}&sz=w1000`;
    } else {
      return image;
    }
  }
  const getDriveDownloadLink = function (link = '') {
    if (link.indexOf('drive.google') !== -1) {
      let driveId = link.split('id=')[1];
      return `https://docs.google.com/uc?export=download&id=${driveId}`;
    } else {
      return link;
    }
  }
  const updateData = async() => {
    const doc = new GoogleSpreadsheet('1M97ecUGTRjdwsJeOxHYEGVYixcuMGbi_HnAnZanE_2A');
    await doc.useServiceAccountAuth(Credentials);
    await doc.loadInfo();
    const magazineData = doc.sheetsByIndex[0];
    let magazineDataRows = await magazineData.getRows();
    setMagazine((magazineDataRows).reverse())
    setLoading(false);
  }
  React.useEffect(() => {
    updateData()
    document.body.classList.add("about-us");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("about-us");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <DropdownFixedNavbar />
      <div className="wrapper about-us-styles">
        <AboutUsHeader />
        <div className="section">
          <div className="about-description pt-0 pb-0">
            <div className="features-3 pt-0 pb-0">
              <Container>
                <Row className="about-us-description">
                  <Col className="mr-auto ml-auto text-left mb-5" md="12">
                    <h2 className="title">¿Qué es ELREPUESTO?</h2>
                    <h4 className="description">
                      El Repuesto, es la publicación binestral de la Cámara de Comerciantes en Repuestos del Automotor (CCRA), entidad fundada en 1955, que nuclea a los integrantes del Mercado de Reposición Independiente.
                    </h4>
                  </Col>
                  {
                    magazine &&
                    magazine.map((item, i) => (
                      <Col md="4" className="mb-5 pl-5 pr-5">
                        <LazyLoadImage
                          alt="..."
                          className="img rounded img-raised mb-4"
                          effect="blur"
                          src={getDriveImages(item.Portada)}
                        />
                        {
                          item.Link &&
                          <p className="description">
                            <a rel="noopener noreferrer" target="_blank" href={item.Link}>Ver revista</a>
                          </p>
                        }
                        {
                          item.Pdf &&
                          <p className="description">
                            <a rel="noopener noreferrer" target="_blank" href={getDriveDownloadLink(item.Pdf)}>Descargar</a>
                          </p>
                        }
                      </Col>
                    ))
                  }
                </Row>
              </Container>
            </div>
          </div>
          <div className="separator-line bg-info"></div>
          <div className="about-contact" id="contacto">
            <Container>
              <Row>
                <Col className="mr-auto ml-auto" md="8">
                  <h2 className="text-center title">Contacto</h2>
                  <h4 className="text-center description">
                    Para consultas o mensajes
                  </h4>
                  <Form className="contact-form" method="post" role="form" action="https://docs.google.com/forms/u/1/d/e/1FAIpQLScFpTqyPSX1-q9fz4a8I2Gb13orquj3B5X0EVsU4RzgK-QO3Q/formResponse?embedded=true">
                    <Row>
                      <Col md="6">
                        <label>Nombre</label>
                        <InputGroup
                          className={firstFocus ? "input-group-focus" : ""}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="now-ui-icons users_circle-08"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            required
                            autoComplete="firstname"
                            placeholder="Ingrese nombre..."
                            type="text"
                            name="entry.1335977823"
                            onFocus={() => setFirstFocus(true)}
                            onBlur={() => setFirstFocus(false)}
                          ></Input>
                        </InputGroup>
                      </Col>
                      <Col md="6">
                        <label>Email</label>
                        <InputGroup
                          className={emailFocus ? "input-group-focus" : ""}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="now-ui-icons ui-1_email-85"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            required
                            autoComplete="email"
                            placeholder="Ingrese el email..."
                            type="email"
                            name="entry.2136098083"
                            onFocus={() => setEmailFocus(true)}
                            onBlur={() => setEmailFocus(false)}
                          ></Input>
                        </InputGroup>
                      </Col>
                      <Col md="12" className="text-area-styles">
                        <FormGroup>
                          <label>Ingrese el mensaje</label>
                          <Input
                            required
                            id="message"
                            name="entry.1132797336"
                            rows="6"
                            type="textarea"
                          ></Input>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="ml-auto mr-auto text-center" md="4">
                        <Button
                          className="btn-round mt-4"
                          color="info"
                          size="lg"
                        >
                          Enviar
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <Footer />
      </div>
      <Dots animation="fade" customLoading={loading} color={'#FFED00'} />
    </>
  );
}

export default AboutUs;
