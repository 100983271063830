import React from "react";
import Mailchimp from 'react-mailchimp-form';
import { Link } from "react-router-dom";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col
} from "reactstrap";

import Logo from "assets/img/logo_bueno.png"

// core components

function FooterSocial() {
  return (
    <>
      <footer className="footer">
        <Container>
          <div className="content">
            <Row>
              <Col md="3">
                <Link to="/inicio" onClick={e => e.preventDefault()}>
                  <h5>Revista ELREPUESTO</h5>
                </Link>
                <a href="https://www.ccra.org.ar/" rel="noopener noreferrer" target="_blank">
                  <img
                    alt="..."
                    className="img ccra-logo"
                    src={Logo}
                  ></img>
                </a>
              </Col>
              <Col md="2">
                <h5>Mapa de sitio</h5>
                <ul className="links-vertical">
                  <li>
                    <Link to="/inicio">
                      Inicio
                    </Link>
                  </li>
                  <li>
                    <Link to="/noticias">
                      Noticias
                    </Link>
                  </li>
                  <li>
                    <Link to="/revista">
                      Revista
                    </Link>
                  </li>
                  <li>
                    <Link to="/revista#contacto">
                      Contacto
                    </Link>
                  </li>
                </ul>
              </Col>
              <Col sm={{ size: 12, offset: 0 }} md={{ size: 4, offset: 3 }}>
                <h5>Suscríbete al newsletter</h5>
                <p>
                  Recibe todas las noticias desde tu casilla de email.
                </p>
                <Mailchimp
                  action='https://revistaelrepuesto.us4.list-manage.com/subscribe/post?u=8210dc663792f69196bec1262&amp;id=338cf58b66'
                  fields={[
                    {
                      name: 'EMAIL',
                      placeholder: 'Ingrese su email',
                      type: 'email',
                      required: true
                    }
                  ]}
                  messages={
                    {
                      sending: "Enviando...",
                      success: "Gracias por suscribirte!",
                      error: "Hubo un error, vuelve a intentarlo.",
                      empty: "Complete el campo con su email.",
                      duplicate: "Este email ya se encuentra registrado.",
                      button: "Suscribirse"
                    }
                  }
                  className="custom-mailchimp-form"
                />
              </Col>
            </Row>
          </div>
          <hr></hr>
          <ul className="social-buttons">
            <li>
              <Button
                className="btn-icon btn-neutral mr-1"
                color="twitter"
                target="_blank"
                href="https://www.instagram.com/revistaelrepuesto"
                size="lg"
              >
                <i className="fab fa-instagram"></i>
              </Button>
            </li>
            <li>
              <Button
                className="btn-icon btn-neutral mr-1"
                color="twitter"
                target="_blank"
                href="https://twitter.com/RepuestoRevista"
                size="lg"
              >
                <i className="fab fa-twitter-square"></i>
              </Button>
            </li>
          </ul>
          <div className="pull-center" id="copyright">
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            Copyright © {new Date().getFullYear()}.
          </div>
        </Container>
      </footer>
    </>
  );
}

export default FooterSocial;
