import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators
} from "reactstrap";

// core components

function Header(props) {
  const { news } = props;
  const carouselSize = 4;
  const newsData = news.slice(0, carouselSize);
  // navbar collapses states and functions
  const [navbarOpen1, setNavbarOpen1] = React.useState(false);
  const [navbarOpen2, setNavbarOpen2] = React.useState(false);
  const [navbarOpen3, setNavbarOpen3] = React.useState(false);
  // header 3 carousel states and functions
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === newsData.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? newsData.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  const getNumbers = function (string) {
    return string.replace(/\D/g, '');
  }
  const getDriveImages = function (image) {
    if (image.indexOf('drive.google') !== -1) {
      let driveId = image.split('id=')[1];
      return `https://drive.google.com/thumbnail?id=${driveId}&sz=w1000`;
    } else {
      return image;
    }
  }
  return (
    <>
      {navbarOpen1 || navbarOpen2 || navbarOpen3 ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setNavbarOpen1(false);
            setNavbarOpen2(false);
            setNavbarOpen3(false);
          }}
        />
      ) : null}
      <div className="cd-section" id="headers">
        <div className="header-3">
          <Carousel activeIndex={activeIndex} next={next} previous={previous}>
            <CarouselIndicators
              items={newsData}
              activeIndex={activeIndex}
              onClickHandler={goToIndex}
            />
            {newsData.map((item, i) => {
              return (
                <CarouselItem
                  onExiting={onExiting}
                  onExited={onExited}
                  key={i}
                >
                  <div className="page-header header-filter">
                    <div
                      className="page-header-image"
                      style={{
                        backgroundImage: `url("${getDriveImages(item.Imágenes)}")`
                      }}
                    ></div>
                    <div className="content">
                      <Container className="text-left">
                        <Row>
                          <Col className="text-left" md="8">
                            <h5 className="category">{item.Categoría}</h5>
                            <h1 className="title">{item.Título}</h1>
                            <h4 className="description">
                              {`${(item.Subtítulo).substring(0, 350)}`}
                              {item.Subtítulo.length > 350 && `...`}
                            </h4>
                            <br></br>
                            <div className="buttons">
                              <Link to={`/artículo?noticia=${getNumbers(item["Marca temporal"])}`}>
                                <Button
                                  className="btn-neutral mr-1"
                                  color="info"
                                  size="lg"
                                >
                                  <i className="now-ui-icons files_single-copy-04"></i> Leer más ...
                                </Button>
                              </Link>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  </div>
                </CarouselItem>
              );
            })}
            <a
              className="left carousel-control carousel-control-prev"
              data-slide="prev"
              href="#pablo"
              onClick={e => {
                e.preventDefault();
                previous();
              }}
              role="button"
            >
              <span className="now-ui-icons arrows-1_minimal-left" />
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="right carousel-control carousel-control-next"
              data-slide="next"
              href="#pablo"
              onClick={e => {
                e.preventDefault();
                next();
              }}
              role="button"
            >
              <span className="now-ui-icons arrows-1_minimal-right" />
              <span className="sr-only">Next</span>
            </a>
          </Carousel>
        </div>
      </div>
    </>
  );
}

export default Header;
