import React, { useState } from "react";
import Credentials from '../../credentials';
import { GoogleSpreadsheet } from 'google-spreadsheet';
import { Dots } from 'react-preloaders';
import { Link } from "react-router-dom";

import Logo from "assets/img/logo.png"

// reactstrap components
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
} from "reactstrap";

function ScrollTransparentNavbar() {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [navbarColor, setNavbarColor] = React.useState(
    (document.documentElement.scrollTop > 499 || document.body.scrollTop) > 499
      ? ""
      : " navbar-transparent"
  );
  const [buyButtonColor, setBuyButtonColor] = React.useState(
    (document.documentElement.scrollTop > 499 || document.body.scrollTop) > 499
      ? "info"
      : "neutral"
  );
  const [categories, setCategories] = useState('');
  const [loading, setLoading] = useState(true);
  const [firstFocus, setFirstFocus] = useState(false);
  const getCategories = async() => {
    setLoading(true);
    const doc = new GoogleSpreadsheet('1M97ecUGTRjdwsJeOxHYEGVYixcuMGbi_HnAnZanE_2A');
    await doc.useServiceAccountAuth(Credentials);
    await doc.loadInfo();
    const categoriesData = doc.sheetsByIndex[2];
    let categoriesDataRows = await categoriesData.getRows();
    setCategories((categoriesDataRows))
    setLoading(false);
  }
  React.useEffect(() => {
    getCategories()
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 499 ||
        document.body.scrollTop > 499
      ) {
        setNavbarColor("");
        setBuyButtonColor("info");
      } else if (
        document.documentElement.scrollTop < 500 ||
        document.body.scrollTop < 500
      ) {
        setNavbarColor(" navbar-transparent");
        setBuyButtonColor("neutral");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  }, [firstFocus]);
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={"fixed-top" + navbarColor} color="white" expand="lg">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand to="/inicio" tag={Link} id="navbar-brand">
              <img
                alt="..."
                className="img"
                src={Logo}
              ></img>
            </NavbarBrand>
            <button
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              className="navbar-toggler"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse isOpen={collapseOpen} navbar>
            <Nav className="ml-auto" id="ceva" navbar>
              <NavItem>
                <NavLink
                  to="/inicio"
                  tag={Link}
                >
                  Inicio
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="/revista" tag={Link}
                >
                  Revista
                </NavLink>
              </NavItem>
              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  data-toggle="dropdown"
                  href="#pablo"
                  id="navbarDropdownMenuLink1"
                  nav
                  onClick={e => e.preventDefault()}
                >
                  <i className="now-ui-icons files_single-copy-04"></i>
                  <p>Noticias</p>
                </DropdownToggle>
                <DropdownMenu aria-labelledby="navbarDropdownMenuLink1" right>
                  {
                    categories.length && categories.map((item, i) =>
                      <DropdownItem href={`/noticias?categoría=${item.Categoría}`}>
                        {item.Categoría}
                      </DropdownItem>
                    )
                  }
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem>
                <NavLink
                  href="https://www.instagram.com/revistaelrepuesto"
                  target="_blank"
                >
                  <i className="fab fa-instagram"></i>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="https://twitter.com/RepuestoRevista"
                  target="_blank"
                >
                  <i className="fab fa-twitter-square"></i>
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
      <Dots customLoading={loading} color={'#FFED00'}/>
    </>
  );
}

export default ScrollTransparentNavbar;
