import React, { useState } from "react";
import Credentials from '../../credentials';
import { GoogleSpreadsheet } from 'google-spreadsheet';

import { Dots } from 'react-preloaders';
import instagram from 'user-instagram';
import Mailchimp from 'react-mailchimp-form';
import { Link } from "react-router-dom";
import { LazyLoadImage, LazyLoadComponent } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import ReadingTime from 'reading-time';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import DropdownFixedNavbar from "pincelit/components/Navbar.js";
import LandingPageHeader from "pincelit/sections/Header.js";
import FooterDefault from "pincelit/components/Footer.js";

function LandingPage() {

  // hardcode values
  const newsSize = 6;

  // carousel
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

  // states
  const [newsDisplay, setNewsDisplay] = useState(newsSize - 1);
  const [firstFocus, setFirstFocus] = useState(false);
  const [news, setNews] = useState([]);
  const [magazine, setMagazine] = useState([]);
  const [ad, setAds] = useState([]);
  const [instagramPosts, setInstagramFeed] = useState([]);
  const [loading, setLoading] = useState(true);
  const carouselSize = 4;
  const updateData = async() => {
    const doc = new GoogleSpreadsheet('1M97ecUGTRjdwsJeOxHYEGVYixcuMGbi_HnAnZanE_2A');
    await doc.useServiceAccountAuth(Credentials);
    await doc.loadInfo();
    const newsData = doc.sheetsByIndex[1];
    const magazineData = doc.sheetsByIndex[0];
    const marketingData = doc.sheetsByIndex[3];
    let newsDataRows = await newsData.getRows();
    let magazineDataRows = await magazineData.getRows();
    let marketingDataRows = await marketingData.getRows();
    setNews((newsDataRows).reverse())
    setMagazine((magazineDataRows).reverse())
    if (marketingDataRows) {
      setAds(marketingDataRows)
    }
    setLoading(false);
  }
  const getDriveImages = function (image = '') {
    if(image.indexOf('drive.google') !== -1){
      let driveId = image.split('id=')[1];
      return `https://drive.google.com/thumbnail?id=${driveId}&sz=w1000`;
    } else {
      return image;
    }
  }
  const getDriveDownloadLink = function (link = '') {
    if (link.indexOf('drive.google') !== -1) {
      let driveId = link.split('id=')[1];
      return `https://docs.google.com/uc?export=download&id=${driveId}`;
    } else {
      return link;
    }
  }
  const getInstagramFeed = function(){
    instagram("https://www.instagram.com/revistaelrepuesto")
      .then(data => {
        setInstagramFeed(data.posts)
      })
      .catch(e => {
        console.error(e)
      })
  }
  const getNumbers = function(string){
    return string.replace(/\D/g, '');
  }
  React.useEffect(() => {
    updateData()
    getInstagramFeed()
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, [firstFocus]);
  return (
    <>
      <DropdownFixedNavbar />
      <div className="wrapper">
        <LazyLoadComponent visibleByDefault>
          <LandingPageHeader news={news} />
        </LazyLoadComponent>
        <Carousel
          responsive={responsive}
          autoPlaySpeed={5000}
          autoPlay={true}
        >
          {
            ad.map((item) => (
              <div className="section">
                <Container>
                  <a rel="noopener noreferrer" target="_blank" href={item["URL"]} >
                    <div>
                      <LazyLoadImage
                        alt="..."
                        className="img rounded img-raised mobile-pub"
                        effect="blur"
                        src={getDriveImages(item["Mobile"])}
                      />
                    </div>
                  </a>
                  <a rel="noopener noreferrer" target="_blank" href={item["URL"]} >
                    <div>
                      <LazyLoadImage
                        alt="..."
                        className="img rounded img-raised desktop-pub"
                        effect="blur"
                        src={getDriveImages(item["Desktop"])}
                      />
                    </div>
                  </a>
                </Container>
              </div>
            ))
          }
        </Carousel>
        <div className="section section-about-us">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-left">
                <h2 className="title">Más noticias</h2>
              </Col>
            </Row>
            <br></br>
            <Row>
              {
                news && news?.length <= carouselSize &&
                <Col className="ml-auto mr-auto" md="8">
                  <div className="section-description text-center">
                    <h4>No se encontraron noticias</h4>
                  </div>
                </Col>
              }
              {
                news.map((item, i) =>
                  (i >= carouselSize) && i <= (carouselSize + newsDisplay) &&
                  <Col key={i} md="4">
                    <Card className="card-plain card-blog">
                        <div className="card-image">
                          <Link to={`/artículo?noticia=${getNumbers(item["Marca temporal"])}`}>
                            <LazyLoadComponent visibleByDefault>
                                <div
                                  className="img rounded img-raised article-img"
                                  style={{
                                    backgroundImage:
                                      "url(" + getDriveImages(item.Imágenes) + ")"
                                  }}
                                ></div>
                            </LazyLoadComponent>
                          </Link>
                        </div>
                      <CardBody>
                        <h6 className="category text-info">{item.Categoría}</h6>
                        <p>{item.Cuerpo && `${Math.ceil(ReadingTime(item.Cuerpo).minutes)} min de lectura`}</p>
                        <CardTitle tag="h4">
                          <Link to={`/artículo?noticia=${getNumbers(item["Marca temporal"])}`}>
                            {item.Título}
                          </Link>
                        </CardTitle>
                        <p className="card-description">
                          {(item.Subtítulo).substring(0, 100)}...
                        <Link to={`/artículo?noticia=${getNumbers(item["Marca temporal"])}`}> Leer más</Link>
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                )
              }
            </Row>
            {
              (newsDisplay + carouselSize) < news?.length &&
              <Row>
                <Col className="ml-auto mr-auto" md="8">
                  <div className="section-description text-center">
                    <Button
                      className="btn-round"
                      color="info"
                      onClick={() => { setNewsDisplay(newsDisplay + newsSize) }}
                    >
                      Ver más
                  </Button>
                  </div>
                </Col>
              </Row>
            }
          </Container>
        </div>
        <div className="subscribe-line subscribe-line-white">
          <Container>
            <Row>
              <Col md="6">
                <h4 className="title">Suscríbete al newsletter</h4>
                <p className="description">
                  Recibe todas las noticias desde tu casilla de email.
                </p>
                <Card className="card-plain card-form-horizontal">
                  <div className="card-content">
                    <Mailchimp
                      action='https://revistaelrepuesto.us4.list-manage.com/subscribe/post?u=8210dc663792f69196bec1262&amp;id=338cf58b66'
                      fields={[
                        {
                          name: 'EMAIL',
                          placeholder: 'Ingrese su email',
                          type: 'email',
                          required: true
                        }
                      ]}
                      messages={
                        {
                          sending: "Enviando...",
                          success: "Gracias por suscribirte!",
                          error: "Hubo un error, vuelve a intentarlo.",
                          empty: "Complete el campo con su email.",
                          duplicate: "Este email ya se encuentra registrado.",
                          button: "Suscribirse"
                        }
                      }
                      className="custom-mailchimp-form"
                    />
                  </div>
                </Card>
              </Col>
              {
                magazine[0] &&
                <Col md="6" className="magazine-container">
                  <LazyLoadImage
                    alt="..."
                    className="img rounded img-raised"
                    effect="blur"
                    src={getDriveImages(magazine[0].Portada)} />
                  <p className="description">
                    Tapa de la última edición.
                  </p>
                  {
                    magazine[0].Link?.length &&
                    <p className="description">
                      <a rel="noopener noreferrer" target="_blank" href={magazine[0].Link}>Ver revista</a>
                    </p>
                  }
                  {
                    magazine[0].Pdf &&
                    <p className="description">
                      <a rel="noopener noreferrer" target="_blank" href={getDriveDownloadLink(magazine[0].Pdf)}>Descargar</a>
                    </p>
                  }
                </Col>
              }
            </Row>
          </Container>
        </div>
        <div className="section section-about-us">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-left">
                <h2 className="title">Redes sociales</h2>
                <h5 className="description">
                  Todas las novedades desde nuestras redes, seguinos!
                </h5>
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col sm="12" md="12">
                <TwitterTimelineEmbed
                  sourceType="profile"
                  screenName="RepuestoRevista"
                  options={{ height: 570 }}
                />
              </Col>
            </Row>
          </Container>
        </div>
        <FooterDefault />
      </div>
      <Dots animation="fade" customLoading={loading} color={'#FFED00'} />
    </>
  );
}

export default LandingPage;
