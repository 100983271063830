import React, { useState } from "react";
import Credentials from '../../credentials';
import { GoogleSpreadsheet } from 'google-spreadsheet';
import { Dots } from 'react-preloaders';
import ReadingTime from 'reading-time';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import FixedTransparentNavbar from "pincelit/components/Navbar.js";
import BlogPostsHeader from "pincelit/components/NewsHeader.js";
import Footer from "pincelit/components/Footer.js";

function News() {
  // hardcode values
  const newsSize = 6;

  // states
  const [news, setNews] = useState([]);
  const [categoryData, setCategoryData] = useState('');
  const [firstFocus, setFirstFocus] = useState(false);
  const [loading, setLoading] = useState(true);
  const [newsDisplay, setNewsDisplay] = useState(newsSize - 1);
  const category = (new URLSearchParams(window.location.search)).get('categoría');
  const getDriveImages = function (image = '') {
    if (image.indexOf('drive.google') !== -1) {
      let driveId = image.split('id=')[1];
      return `https://drive.google.com/thumbnail?id=${driveId}&sz=w1000`;
    } else {
      return image;
    }
  }
  const getNumbers = function (string) {
    return string.replace(/\D/g, '');
  }
  const setNewsData = function (data) {
    // eslint-disable-next-line array-callback-return
    let news =  data.reverse().filter((item, i) => {
      item.Id = i;
      if (!category) {
        return item;
      } else if (category === item.Categoría) {
        return item;
      }
    })
    setNews(news);
  }
  const setCategory = function (data) {
    let categoryData = data.filter(item => {
      if (!category) {
        return item.Categoría;
      }
      return category === item.Categoría;
    });
    setCategoryData(categoryData[0]);
  }
  const setData = function (news, categories) {
    setNewsData(news);
    setCategory(categories);
  }
  const updateNewsService = async() => {
    const doc = new GoogleSpreadsheet('1M97ecUGTRjdwsJeOxHYEGVYixcuMGbi_HnAnZanE_2A');
    await doc.useServiceAccountAuth(Credentials);
    await doc.loadInfo();
    const newsData = doc.sheetsByIndex[1];
    const categoriesData = doc.sheetsByIndex[2];
    let newsDataRows = await newsData.getRows();
    let categoriesDataRows = await categoriesData.getRows();
    setData(newsDataRows, categoriesDataRows);
    setLoading(false);
  }
  React.useEffect(() => {
    updateNewsService()
    document.body.classList.add("blog-posts");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("blog-posts");
      document.body.classList.remove("sidebar-collapse");
    };
  }, [firstFocus, updateNewsService]);
  return (
    <>
      <FixedTransparentNavbar />
      <div className="wrapper">
        <BlogPostsHeader image={categoryData.Imagen} title="Noticias" subtitle={categoryData.Categoría} />
        <div className="section section-about-us">
          <Container>
            <Row>
              {
                news.map((item, i) =>
                  i <= (newsDisplay) &&
                  <Col data-js={item.Id} key={item.Id} md="4">
                    <Card className="card-plain card-blog">
                      <div className="card-image">
                        <a href={`/artículo?noticia=${getNumbers(item["Marca temporal"])}`}>
                          <div
                            className="img rounded img-raised article-img"
                            style={{
                              backgroundImage:
                                "url(" + getDriveImages(item.Imágenes) + ")"
                            }}
                          ></div>
                        </a>
                      </div>
                      <CardBody>
                        <h6 className="category text-info">{item.Categoría}</h6>
                        <p>{item.Cuerpo && `${Math.ceil(ReadingTime(item.Cuerpo).minutes)} min de lectura`}</p>
                        <CardTitle tag="h4">
                          <a href={`/artículo?noticia=${getNumbers(item["Marca temporal"])}`}>
                            {item.Título}
                          </a>
                        </CardTitle>
                        <p className="card-description">
                          {(item.Subtítulo).substring(0, 100)}...
                        <a href={`/artículo?noticia=${getNumbers(item["Marca temporal"])}`}> Leer más</a>
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                )
              }
              {
                !news.length &&
                <Col className="ml-auto mr-auto" md="8">
                  <div className="section-description text-center">
                    <h4>No se encontraron noticias</h4>
                  </div>
                </Col>
              }
            </Row>
            {
              (newsDisplay + 1) < news.length &&
              <Row>
                <Col className="ml-auto mr-auto" md="8">
                  <div className="section-description text-center">
                    <Button
                      className="btn-round"
                      color="info"
                      onClick={() => { setNewsDisplay(newsDisplay + newsSize) }}
                    >
                      Ver más
                  </Button>
                  </div>
                </Col>
              </Row>
            }
          </Container>
        </div>
        <Footer />
        <Dots customLoading={loading} color={'#FFED00'} />
      </div>
    </>
  );
}

export default News;
