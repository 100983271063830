import React, { useState } from "react";
import Credentials from '../../credentials';
import { GoogleSpreadsheet } from 'google-spreadsheet';
import Parser from 'html-react-parser';
import { Dots } from 'react-preloaders';
import Linkify from 'react-linkify';
import ReadingTime from 'reading-time';
import Carousel from 'react-img-carousel';
import Carousel2 from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { LazyLoadImage, LazyLoadComponent } from 'react-lazy-load-image-component';

// share btns
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import FixedTransparentNavbar from "pincelit/components/Navbar.js";
import BlogPostsHeader from "pincelit/components/NewsHeader.js";
import Footer from "pincelit/components/Footer.js";

function Article() {

  // carousel
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

  // states
  const [article, setArticle] = useState([]);
  const [categoryData, setCategoryData] = useState('');
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const [ad, setAds] = useState([]);
  const [firstFocus, setFirstFocus] = useState(false);
  const [loading, setLoading] = useState(true);
  const newId = (new URLSearchParams(window.location.search)).get('noticia');
  const stringToArray = function (string = '') {
    return string.split(',');
  }
  const getDriveImages = function (image = '') {
    if (image.indexOf('drive.google') !== -1) {
      let driveId = image.split('id=')[1];
      return `https://drive.google.com/thumbnail?id=${driveId}&sz=w1000`;
    } else {
      return image;
    }
  }
  const getDriveVideos = function (video = '') {
    if (video.indexOf('drive.google') !== -1) {
      let driveId = video.split('id=')[1];
      return `https://drive.google.com/uc?export=download&id=${driveId}`;
    } else {
      return video;
    }
  }
  const removeHour = function(date){
    return date.split(" ")[0]
  }
  const getNumbers = function (string) {
    return string.replace(/\D/g, '');
  }
  const updateNews = async() => {
    const doc = new GoogleSpreadsheet('1M97ecUGTRjdwsJeOxHYEGVYixcuMGbi_HnAnZanE_2A');
    await doc.useServiceAccountAuth(Credentials);
    await doc.loadInfo();
    const newsData = doc.sheetsByIndex[1];
    const categoriesData = doc.sheetsByIndex[2];
    const marketingData = doc.sheetsByIndex[3];
    let newsDataRows = await newsData.getRows();
    let categoriesDataRows = await categoriesData.getRows();
    let marketingDataRows = await marketingData.getRows();
    
    let articleData = (newsDataRows).find(item => {
      return newId === getNumbers(item["Marca temporal"]);
    });
    let categoryData = (categoriesDataRows).find(item => {
      return articleData.Categoría === item.Categoría;
    });
    setArticle(articleData);
    setCategoryData(categoryData);
    if (marketingDataRows) {
      setAds(marketingDataRows)
    }
    setLoading(false);
  }
  React.useEffect(() => {
    updateNews()
    document.body.classList.add("blog-posts");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("blog-posts");
      document.body.classList.remove("sidebar-collapse");
    };
  }, [firstFocus, updateNews]);
  return (
    <>
      <FixedTransparentNavbar />
      <div className="wrapper article-styles">
        <BlogPostsHeader image={categoryData.Imagen} title={`Noticias`} subtitle={categoryData.Categoría} />
        <div className="section pb-0 mb-0">
          <div className="section">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto custom-carousel" md="12">
                  <h3 className="title">
                    {article.Título}
                  </h3>
                  <h4 className="subtitle">
                    <Linkify>{article.Subtítulo}</Linkify>
                  </h4>
                  <hr />
                  <p>
                    Publicado el {removeHour(`${article["Marca temporal"]}`)}{article.Cuerpo && ` | ${Math.ceil(ReadingTime(article.Cuerpo).minutes)} min de lectura`}
                  </p>
                  {
                    (stringToArray(article.Imágenes)).length > 1
                      ? <Carousel viewportWidth="100%" cellPadding={30}>
                          {(stringToArray(article.Imágenes)).map((item, i) => {
                            return (
                              <img
                                alt="..."
                                className="img rounded img-raised"
                                src={getDriveImages(item)}
                              ></img>
                            );
                          })}
                        </Carousel>
                      : <img
                        alt="..."
                        className="img rounded img-raised"
                        src={getDriveImages(article.Imágenes)}
                      ></img>
                  }
                  {
                    article.Video &&
                    <video className="img rounded img-raised video-styles" src={getDriveVideos(article.Video)} controls>
                      Tu navegador no soporta este formato de video.
                    </video>
                  }
                  <p class="image-description">
                    <Linkify>{article.Descripción}</Linkify>
                  </p>
                  <p>
                    <Linkify>{Parser(article.Cuerpo + '')}</Linkify>
                  </p>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="section section-blog-info">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" md="12">
                  <Row>
                    <Col md="12">
                      <EmailShareButton url={window.location.href}>
                        <Button
                          className="btn-round"
                          color="google"
                        >
                          <i className="now-ui-icons ui-1_email-85"></i>
                        </Button>
                      </EmailShareButton>
                      <FacebookShareButton url={window.location.href}>
                        <Button
                          className="btn-round"
                          color="facebook"
                        >
                          <i className="fab fa-facebook-square"></i>
                        </Button>
                      </FacebookShareButton>
                      <TwitterShareButton url={window.location.href}>
                        <Button
                          className="btn-round"
                          color="twitter"
                        >
                          <i className="fab fa-twitter"></i>
                        </Button>
                      </TwitterShareButton>
                      <WhatsappShareButton url={window.location.href}>
                        <Button
                          className="btn-round"
                          color="whatsapp"
                        >
                          <i className="fab fa-whatsapp"></i>
                        </Button>
                      </WhatsappShareButton>
                    </Col>
                  </Row>
                </Col>
              </Row>
              
            </Container>
          </div>
        </div>
        <Carousel2
          responsive={responsive}
          autoPlaySpeed={5000}
          autoPlay={true}
        >
          {
            ad.map((item) => (
              <div className="section">
                <Container>
                  <a rel="noopener noreferrer" target="_blank" href={item["URL"]} >
                    <div>
                      <LazyLoadImage
                        alt="..."
                        className="img rounded img-raised mobile-pub"
                        effect="blur"
                        src={getDriveImages(item["Mobile"])}
                      />
                    </div>
                  </a>
                  <a rel="noopener noreferrer" target="_blank" href={item["URL"]} >
                    <div>
                      <LazyLoadImage
                        alt="..."
                        className="img rounded img-raised desktop-pub"
                        effect="blur"
                        src={getDriveImages(item["Desktop"])}
                      />
                    </div>
                  </a>
                </Container>
              </div>
            ))
          }
        </Carousel2>
        <Footer />
        <Dots customLoading={loading} color={'#FFED00'} />
      </div>
    </>
  );
}

export default Article;
